import { useEffect, useState, useRef } from "react"
import * as React from "react"
import styled, { css } from "styled-components"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { DesktopVersionContainer, device } from "../../utils/Device"
import { CategoryType, EditTab, Field } from "../myprofile/MyProfilePageContentBranding"
import { IconMenu, IconPercentage, IconIndicatorErrorBlack } from "../../ui/Icons"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { Col, Row } from "react-bootstrap"
import {
    areRequiredFieldsEmpty,
    BasicInfo,
    BuySell,
    areRequiredInterestsEmpty,
    Interests,
    Socials,
    areSocialMediaLinksCorrect,
    isImageSizeValid,
    trimValues,
    getUserPayload,
    areAllFieldsEmpty,
    isCategorySelectionEmpty,
    phoneNumbersValidationCorrect,
    Attributes,
    getAttributesValidationErrors,
    isAttributesSelectionEmpty
} from "../myprofile/EditMyProfileLayout"
import { useLoggedInState, User } from "../../globalStates/LoggedInUser"
import { Category, UserQuestion } from "../../backendServices/Types"
import {
    changeProfileImage,
    deleteUserProfile,
    getInterest,
    getSelfDefinedUserRolesByTopic,
    getUserQuestions,
    SelfDefinedUserRole,
    updateMyProfileData
} from "../../backendServices/SeriesOfTopicsUserServices"
import { CategoryData } from "../myprofile/ViewMyProfileLayout"
import { logoutUser } from "../../communicationArea/settings/SettingsTab"
import { useHistory } from "react-router-dom"
import { useAppState } from "../../globalStates/AppState"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useChimeContext } from "../../conference/context/ChimeContext"
import LanguageToggler from "../../ui/LanguageToggle"
import ConfirmationModal from "../../ui/modals/ConfirmationModal"
import UserRegistrationConfirmationModal from "../../ui/modals/UserRegistrationConfirmationModal"
import { defaultLogger as logger } from "../../globalStates/AppState"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import CenteredLoader from "../../ui/CenteredLoader"
import { updateUserValues } from "../../backendServices/GraphQLServices"
import DataUpdateAlert from "../../ui/DataUpdateAlert"
import { isUserEdited } from "../../App"

interface UserRegistrationSiteProps {
    setUserEdited: (value: boolean) => void
}

const UserRegistrationSite: React.FunctionComponent<UserRegistrationSiteProps> = (props: UserRegistrationSiteProps) => {
    const [activeTab, setActiveTab] = useState<EditTab>(EditTab.BASIC_INFO)
    const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(true)
    const [clickableTabs, setClickableTabs] = useState<EditTab[]>([])

    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()

    useEffect(() => {
        if (isUserEdited(userState)) {
            props.setUserEdited(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <UserRegistrationSiteRoot>
            {showWelcomeModal && (
                <ConfirmationModal
                    dialogWidth="480px"
                    title={strings.userRegistrationSite.welcomeModalTitle}
                    text={strings.userRegistrationSite.welcomeModalText.replaceAll("${fairName}", branding.pageTitle)}
                    acceptButtonText={strings.userRegistrationSite.welcomeModalConfirmButtonText}
                    dismissButtonText={strings.globalTexts.cancel}
                    hideDisagreeButton
                    accept={() => {
                        setShowWelcomeModal(false)
                    }}
                    dismiss={() => setShowWelcomeModal(false)}
                />
            )}
            <Header activeTab={activeTab} setActiveTab={setActiveTab} clickableTabs={clickableTabs} />
            <Content
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setUserEdited={props.setUserEdited}
                setClickableTabs={setClickableTabs}
            />
        </UserRegistrationSiteRoot>
    )
}

export default UserRegistrationSite

const UserRegistrationSiteRoot = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    font-family: ${branding.font1};
`

const HeaderRoot = styled.div<{ menuVisible: boolean }>`
    position: sticky;
    background-color: #fff;
    height: 73px;
    -webkit-box-shadow: ${(props) => (props.menuVisible ? "none" : branding.primaryScrollDarkShadowTTB)};
    -moz-box-shadow: ${(props) => (props.menuVisible ? "none" : branding.primaryScrollDarkShadowTTB)};
    box-shadow: ${(props) => (props.menuVisible ? "none" : branding.primaryScrollDarkShadowTTB)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 34px 24px 34px;
    z-index: 1;

    @media ${device.mobile} {
        padding: 24px 0px 24px 34px;
    }
`

const HeaderRow = styled(Row)`
    width: 100%;
`

const HeaderLogo = styled.img`
    max-width: 110px;
    max-height: 24px;
    object-fit: contain;
    user-select: none;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media ${device.tablet} {
        max-height: 50px;
    }

    @media ${device.mobile} {
        margin-top: 3px;
    }
`

const HeaderOption = styled.div`
    user-select: none;
    transition: 0.5s;

    &.selected {
        color: ${branding.userRegistrationSite.selectedTabColor};
        pointer-events: none;
    }

    &.clickable {
        color: ${branding.userRegistrationSite.previousTabColor};
        cursor: pointer;
    }

    &.nonclickable {
        color: ${branding.userRegistrationSite.nextTabColor};
        pointer-events: none;
    }
`

const HeaderOptionsHorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    line-height: 21px;

    @media (max-width: 1300px) {
        gap: 40px;
        font-size: 16px;
    }

    @media (max-width: 1100px) {
        gap: 20px;
        justify-content: flex-end;
        font-size: 16px;
    }
`

const HeaderOptionsVerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: calc(100vh - 73px);
    width: 100%;
    background: #fff !important;
    position: absolute;
    top: 73px;
    z-index: 2000;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: end;
    padding-top: 15px;

    -webkit-box-shadow: ${branding.primaryScrollDarkShadowTTB};
    -moz-box-shadow: ${branding.primaryScrollDarkShadowTTB};
    box-shadow: ${branding.primaryScrollDarkShadowTTB};

    ${HeaderOption} {
        padding: 0px 30px 30px 0px;
        font-size: 18px;
    }
`

const IconContainer = styled.div`
    cursor: pointer;
    float: right;
`

interface HeaderProps {
    activeTab: EditTab
    setActiveTab: (value: EditTab) => void
    clickableTabs: EditTab[]
}

const Header: React.FunctionComponent<HeaderProps> = (props: HeaderProps) => {
    const strings = useLanguageState().getStrings()
    const { useMobileDesign } = useWindowDimensions()
    const windowSize = useWindowDimensions()

    const [menuVisible, setMenuVisible] = useState<boolean>(false)

    const tabsList = branding.userRegistrationSite.tabs
    let activeTabIndex: number = tabsList.indexOf(props.activeTab)

    function updateTab(tab: EditTab, position: "selected" | "clickable" | "nonclickable") {
        if (position !== "clickable") {
            return
        }
        props.setActiveTab(tab)
    }

    const options: JSX.Element = (
        <>
            {tabsList.map((tab: EditTab, index: number) => {
                let position: "selected" | "clickable" | "nonclickable" =
                    index === activeTabIndex
                        ? "selected"
                        : index < activeTabIndex || props.clickableTabs.includes(tab)
                        ? "clickable"
                        : "nonclickable"

                switch (tab) {
                    case EditTab.BASIC_INFO: {
                        return (
                            <HeaderOption key={index} className={position} onClick={() => updateTab(tab, position)}>
                                {strings.myProfilePageContent.basicInfoTabTitle}
                            </HeaderOption>
                        )
                    }

                    case EditTab.CONTACT: {
                        return (
                            <HeaderOption key={index} className={position} onClick={() => updateTab(tab, position)}>
                                {strings.myProfilePageContent.contactTabTitle}
                            </HeaderOption>
                        )
                    }

                    case EditTab.ATTRIBUTES: {
                        return (
                            <HeaderOption key={index} className={position} onClick={() => updateTab(tab, position)}>
                                {strings.myProfilePageContent.attributesTabTitle}
                            </HeaderOption>
                        )
                    }

                    case EditTab.SOCIAL_MEDIA: {
                        return (
                            <HeaderOption key={index} className={position} onClick={() => updateTab(tab, position)}>
                                {strings.myProfilePageContent.socialMediaSectionTitle}
                            </HeaderOption>
                        )
                    }

                    case EditTab.INTERESTS: {
                        return (
                            <HeaderOption key={index} className={position} onClick={() => updateTab(tab, position)}>
                                {strings.myProfilePageContent.interestsLabel}
                            </HeaderOption>
                        )
                    }

                    case EditTab.BUY_SELL: {
                        return (
                            <HeaderOption key={index} className={position} onClick={() => updateTab(tab, position)}>
                                {strings.myProfilePageContent.buySellTabTitle}
                            </HeaderOption>
                        )
                    }

                    default:
                        return null
                }
            })}
        </>
    )

    return (
        <>
            <HeaderRoot menuVisible={menuVisible}>
                <HeaderRow>
                    <Col xs={6} md={3}>
                        <HeaderLogo
                            src={
                                branding.loginRegistrationSite.useCustomLogoForMobile
                                    ? "/branding/logo_mobile.png"
                                    : "/branding/logo.png"
                            }
                            alt={strings.configuration.ogTitle}
                        />
                    </Col>

                    {!useMobileDesign && (
                        <Col
                            xs={windowSize.width > 1100 || useMobileDesign ? 12 : 18}
                            md={windowSize.width > 1100 || useMobileDesign ? 6 : 9}
                        >
                            <HeaderOptionsHorizontalContainer>{options}</HeaderOptionsHorizontalContainer>
                        </Col>
                    )}

                    {(windowSize.width > 1100 || useMobileDesign) && (
                        <Col xs={6} md={3}>
                            <div>
                                {useMobileDesign && (
                                    <IconContainer onClick={() => setMenuVisible(!menuVisible)}>
                                        {IconMenu({
                                            fill: menuVisible
                                                ? branding.crsTabs.defaultActionItemActiveStateColor
                                                : branding.sideIconBar.sideIconColorDark,
                                            width: "20",
                                            height: "20"
                                        })}
                                    </IconContainer>
                                )}
                            </div>
                        </Col>
                    )}
                </HeaderRow>
            </HeaderRoot>

            {menuVisible && (
                <HeaderOptionsVerticalContainer>
                    {options}

                    <HeaderOption key={tabsList.length + 1}>
                        <LanguageToggler color={branding.sideIconBar.sideIconColorDark} fontSize="16px" fullToggler margin="0" />
                    </HeaderOption>
                </HeaderOptionsVerticalContainer>
            )}
        </>
    )
}

const ContentRoot = styled.div`
    @media ${device.mobile} {
        width: 87%;
        margin-left: 8%;
    }
`

const SectionRoot = styled.div`
    &.ATTRIBUTES  {
        padding-bottom: 100px;
    }

    @media (max-height: 720px) {
        padding-bottom: 100px;
    }

    @media ${device.mobile} {
        padding-bottom: 150px;
    }
`

const BottomNavigationContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;

    -webkit-box-shadow: ${branding.primaryScrollDarkShadowBTT};
    -moz-box-shadow: ${branding.primaryScrollDarkShadowBTT};
    box-shadow: ${branding.primaryScrollDarkShadowBTT};
`

const PercentageRoot = styled.div`
    display: flex;
    flex-direction: column;
    margin: 50px 20px 50px 0;

    @media ${device.mobile} {
        margin: 50px 20px 30px 0;
    }
`

const PercentageTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 15px;

    .title {
        font-weight: bold;
    }

    @media ${device.mobile} {
        font-size: 14px;
    }
`

const PercentageBarContainer = styled.div``

function getPercentage(numberOfEditedTabs: number): number {
    return Math.round((100 * numberOfEditedTabs) / branding.userRegistrationSite.tabs.length)
}

interface ContentProps {
    activeTab: EditTab
    setActiveTab: (value: EditTab) => void
    setUserEdited: (value: boolean) => void
    setClickableTabs: (value: EditTab[]) => void
}

const Content: React.FunctionComponent<ContentProps> = (props: ContentProps) => {
    let userState = useLoggedInState()

    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()

    const windowSize = useWindowDimensions()

    const percentageTitleRef = useRef<HTMLDivElement>(null)

    const [isLoaded, setIsLoaded] = useState<boolean>(true)

    const [percentage, setPercentage] = useState<number>(0)

    const [user, setUser] = useState<User>(userState.user()!)
    const [basicInfoLang, setBasicInfoLang] = useState<string>(lang)

    const [userRoles, setUserRoles] = useState<SelfDefinedUserRole[]>([])

    const [showAlert, setShowAlert] = useState<boolean>(false)

    const [imageDimensions, setImageDimensions] = useState<any>({ height: 0, width: 0 })
    const [imageSize, setImageSize] = useState<number>(0)
    const [imageFile, setImageFile] = useState<any>(null)

    const [showProfileEditedConfirmation, setShowProfileEditedConfirmation] = useState<boolean>(false)

    const [visitedTabs, setVisitedTabs] = useState<EditTab[]>([])

    const [userQuestions, setUserQuestions] = useState<UserQuestion[]>([])

    const tabsList = branding.userRegistrationSite.tabs

    const [categoryData, setCategoryData] = useState<CategoryData>({
        interests: [],
        offerings: [],
        lookingfor: [],
        selectedInterests: [],
        selectedOfferings: [],
        selectedLookingfor: []
    })

    function getData() {
        getInterest().then((res) => {
            if (res.content.interests) {
                const interests: Category[] = res.content.interests ?? []
                const offerings: Category[] = res.content.lookingforandoffering ?? []
                const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                const selectedInterests = interests.filter((i) => (userState.user()?.interests ?? []).includes(i.id))
                const selecteOfferings = offerings.filter((i) => (userState.user()?.offering ?? []).includes(i.id))
                const selectedLookingfor = lookingfor.filter((i) => (userState.user()?.lookingfor ?? []).includes(i.id))
                setCategoryData({
                    interests: interests,
                    offerings: offerings,
                    lookingfor: lookingfor,
                    selectedInterests: selectedInterests,
                    selectedOfferings: selecteOfferings,
                    selectedLookingfor: selectedLookingfor
                })
            }
        })

        getSelfDefinedUserRolesByTopic().then((res) => {
            if (res.content.selfDefinedUserRoles) {
                setUserRoles(res.content.selfDefinedUserRoles)
            }
        })

        getUserQuestions().then((res) => {
            if (res.content.questions) {
                setUserQuestions(res.content.questions)
            }
        })
    }

    function getSection() {
        switch (props.activeTab) {
            case EditTab.BASIC_INFO: {
                return (
                    <BasicInfo
                        user={user}
                        setUser={setUser}
                        basicInfoLang={basicInfoLang}
                        setBasicInfoLang={setBasicInfoLang}
                        userRegistrationPage
                        showLanguageSelector
                        fieldsList={branding.userRegistrationSite.basicInfoFieldsList}
                        userRoles={userRoles}
                        setImageDimensions={setImageDimensions}
                        setImageSize={setImageSize}
                        setImageFile={setImageFile}
                    />
                )
            }

            case EditTab.CONTACT: {
                return (
                    <BasicInfo
                        user={user}
                        setUser={setUser}
                        basicInfoLang={basicInfoLang}
                        setBasicInfoLang={setBasicInfoLang}
                        userRoles={userRoles}
                        fieldsList={branding.userRegistrationSite.contactFieldsList}
                    />
                )
            }

            case EditTab.ATTRIBUTES: {
                return (
                    <Attributes
                        user={user}
                        setUser={setUser}
                        basicInfoLang={basicInfoLang}
                        setBasicInfoLang={setBasicInfoLang}
                        userRoles={userRoles}
                        fieldsList={branding.userRegistrationSite.contactFieldsList}
                        questions={userQuestions}
                    />
                )
            }

            case EditTab.SOCIAL_MEDIA: {
                return <Socials user={user} setUser={setUser} basicInfoLang={basicInfoLang} setBasicInfoLang={setBasicInfoLang} />
            }

            case EditTab.INTERESTS: {
                return <Interests categoryData={categoryData} setCategoryData={setCategoryData} />
            }

            case EditTab.BUY_SELL: {
                return <BuySell categoryData={categoryData} setCategoryData={setCategoryData} />
            }

            default:
                return null
        }
    }

    function updateValues() {
        updateMyProfileData({
            profileId: userState.user()!.profileId,
            profileData: getUserPayload(userState, user, categoryData)
        }).then(async (res) => {
            if ((res.content as BackendServiceError).httpStatus) {
                logger.error("failure updating profile data for user " + userState.user()?.profileId)

                setIsLoaded(true)

                setShowAlert(true)
            } else {
                res.content.profileId = res.content.id
                userState.setMatchActive(res.content.matchActive)
                userState.setUser(res.content)
            }

            const userObject = userState.user()!
            const userName = [userObject.firstName, userObject.middleName, userObject.lastName].filter(Boolean).join(" ")
            await updateUserValues({
                id: userObject.profileId,
                name: userName,
                pictureUrl: userObject.logoUrl,
                presenceStatus: userObject.presence,
                lastConnected: new Date().toISOString()
            })

            setIsLoaded(true)
            setShowProfileEditedConfirmation(!showAlert)
        })
    }

    function handleDataChange() {
        setIsLoaded(false)

        if (imageFile && imageFile !== null) {
            let imageData = new FormData()
            imageData.append("image", imageFile)

            changeProfileImage({ profileId: userState.user()?.profileId || "", data: imageData })
                .then((res) => {
                    if (res.logoUrl) {
                        userState.setProfileImage(res.logoUrl || "")
                        setUser({ ...user, logoUrl: res.logoUrl })

                        updateValues()
                    }
                })
                .catch((error) => {
                    logger.error({
                        message: "EditMyProfileLayout Profile Logo Upload failed",
                        errorMessage: error.message,
                        errorStack: error.stack
                    })

                    setIsLoaded(true)
                    setShowAlert(true)
                })
        } else {
            updateValues()
        }
    }

    function saveProfile() {
        trimValues(user, setUser)
        handleDataChange()
    }

    function getValidationErrors(tab: EditTab) {
        if (tab === EditTab.BASIC_INFO) {
            if (branding.userRegistrationSite.basicInfoFieldsList.includes(Field.LOGO) && imageFile && imageFile !== null) {
                return (
                    areRequiredFieldsEmpty(user, branding.userRegistrationSite.basicInfoFieldsList, basicInfoLang) ||
                    !phoneNumbersValidationCorrect(user, branding.userRegistrationSite.basicInfoFieldsList) ||
                    !isImageSizeValid(imageSize, imageDimensions)
                )
            } else {
                return areRequiredFieldsEmpty(user, branding.userRegistrationSite.basicInfoFieldsList, basicInfoLang)
            }
        } else if (tab === EditTab.CONTACT) {
            return (
                areRequiredFieldsEmpty(user, branding.userRegistrationSite.contactFieldsList, basicInfoLang) ||
                !phoneNumbersValidationCorrect(user, branding.userRegistrationSite.contactFieldsList)
            )
        } else if (tab === EditTab.ATTRIBUTES) {
            return getAttributesValidationErrors(user, userQuestions)
        } else if (tab === EditTab.SOCIAL_MEDIA) {
            return (
                !areSocialMediaLinksCorrect(user) ||
                areRequiredFieldsEmpty(user, branding.userRegistrationSite.socialMediaFieldsList, basicInfoLang)
            )
        } else if (tab === EditTab.INTERESTS) {
            return areRequiredInterestsEmpty(categoryData)
        } else if (tab === EditTab.BUY_SELL) {
            return false
        }

        return false
    }

    function getNumberOfEditedTabs(): number {
        let number: number = 0

        tabsList.forEach((tab: EditTab) => {
            if (tab === EditTab.BASIC_INFO) {
                if (!areAllFieldsEmpty(user, branding.userRegistrationSite.basicInfoFieldsList, basicInfoLang)) {
                    number += 1
                }
            } else if (tab === EditTab.CONTACT) {
                if (!areAllFieldsEmpty(user, branding.userRegistrationSite.contactFieldsList, basicInfoLang)) {
                    number += 1
                }
            } else if (tab === EditTab.ATTRIBUTES) {
                if (!isAttributesSelectionEmpty(user, userQuestions)) {
                    number += 1
                }
            } else if (tab === EditTab.SOCIAL_MEDIA) {
                if (!areAllFieldsEmpty(user, branding.userRegistrationSite.socialMediaFieldsList, basicInfoLang)) {
                    number += 1
                }
            } else if (tab === EditTab.INTERESTS) {
                if (!isCategorySelectionEmpty(categoryData, CategoryType.INTERESTS)) {
                    number += 1
                }
            } else if (tab === EditTab.BUY_SELL) {
                if (
                    !(
                        isCategorySelectionEmpty(categoryData, CategoryType.OFFERS) &&
                        isCategorySelectionEmpty(categoryData, CategoryType.NEEDS)
                    )
                ) {
                    number += 1
                }
            }
        })

        return number
    }

    function getClickableTabs(): EditTab[] {
        let clickableTabs: EditTab[] = []

        if (getValidationErrors(props.activeTab)) {
            return []
        }

        for (var i = 0; i < tabsList.length; i++) {
            if (visitedTabs.includes(tabsList[i])) {
                clickableTabs.push(tabsList[i])
            }

            if (getValidationErrors(tabsList[i])) {
                break
            }
        }

        return clickableTabs
    }

    useEffect(() => {
        getData()
    }, [lang]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props.setClickableTabs(getClickableTabs())
        // eslint-disable-next-line
    }, [props.activeTab, getValidationErrors(props.activeTab)])

    let small = windowSize.width < 1400
    let extraSmall = windowSize.width < 1000

    let mdSideWidth = extraSmall ? 1 : small ? 2 : 3
    let xsSideWidth = extraSmall ? 2 : small ? 4 : 6

    let mdMainWidth = extraSmall ? 10 : small ? 8 : 6
    let xsMainWidth = extraSmall ? 20 : small ? 16 : 12

    return (
        <>
            <ContentRoot>
                <ContentScrollContainer adjustForHeaderWith="73px">
                    <Row>
                        <Col xs={xsSideWidth} md={mdSideWidth} />
                        <Col xs={xsMainWidth} md={mdMainWidth}>
                            {user && (
                                <>
                                    <PercentageRoot>
                                        <PercentageTitleContainer ref={percentageTitleRef}>
                                            <div className="title">{strings.userRegistrationSite.completeYourProfile}</div>
                                            <div>
                                                {percentage.toString()}% {strings.userRegistrationSite.percentageCompleteSuffix}
                                            </div>
                                        </PercentageTitleContainer>
                                        <PercentageBarContainer>
                                            <div>
                                                {IconPercentage({
                                                    fill: "#D9D9D9",
                                                    width:
                                                        percentageTitleRef && percentageTitleRef?.current
                                                            ? percentageTitleRef?.current!.clientWidth?.toString() + "px"
                                                            : "100%"
                                                })}
                                            </div>
                                            <div style={{ marginTop: "-24px" }}>
                                                {IconPercentage({
                                                    fill: branding.userRegistrationSite.selectedTabColor,
                                                    width:
                                                        percentageTitleRef && percentageTitleRef?.current
                                                            ? (
                                                                  percentageTitleRef?.current!.clientWidth! *
                                                                  (percentage / 100)
                                                              ).toString() + "px"
                                                            : "100%"
                                                })}
                                            </div>
                                        </PercentageBarContainer>
                                        {!isLoaded && <CenteredLoader />}
                                    </PercentageRoot>

                                    <SectionRoot className={props.activeTab.toString()}>{getSection()}</SectionRoot>
                                </>
                            )}
                        </Col>
                        <Col xs={xsSideWidth} md={mdSideWidth} />
                    </Row>
                </ContentScrollContainer>
            </ContentRoot>
            <BottomNavigationContainer>
                <Row>
                    <Col xs={xsSideWidth} md={mdSideWidth}>
                        <DesktopVersionContainer style={{ marginTop: "60px", marginLeft: "15px" }}>
                            <LanguageToggler color={branding.sideIconBar.sideIconColorDark} fontSize="16px" />
                        </DesktopVersionContainer>
                    </Col>
                    <Col xs={xsMainWidth} md={mdMainWidth} style={{ background: "#fff" }}>
                        <BottomNavigation
                            activeTab={props.activeTab}
                            setActiveTab={(nextTab) => {
                                props.setActiveTab(nextTab)

                                if (visitedTabs.includes(nextTab)) {
                                    return
                                } else {
                                    setVisitedTabs(visitedTabs.concat(nextTab))
                                }
                            }}
                            numberOfEditedTabs={getNumberOfEditedTabs()}
                            setPercentage={setPercentage}
                            validationErrors={getValidationErrors(props.activeTab)}
                            showProfileEditedConfirmation={showProfileEditedConfirmation}
                            onConfirmationModalClose={() => {
                                setShowProfileEditedConfirmation(false)
                                props.setUserEdited(true)
                            }}
                            saveProfile={saveProfile}
                        />
                    </Col>
                    <Col xs={xsSideWidth} md={mdSideWidth} />
                </Row>
            </BottomNavigationContainer>
            {showAlert && (
                <DataUpdateAlert
                    message={strings.myProfilePageContent.backendErrorWarning}
                    close={() => setShowAlert(false)}
                    show={showAlert}
                    type="edit-profile"
                    titleImage={IconIndicatorErrorBlack({ fill: branding.dangerButtonColor })}
                    position="top"
                />
            )}
        </>
    )
}

const BottomNavigationRoot = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin-bottom: 30px;
    padding-top: 20px;

    @media ${device.mobile} {
        flex-direction: column;
        margin-bottom: 0px;
        width: 100%;
        padding: 10px 10px 0 10px;
        -webkit-box-shadow: ${branding.primaryScrollDarkShadowBTT};
        -moz-box-shadow: ${branding.primaryScrollDarkShadowBTT};
        box-shadow: ${branding.primaryScrollDarkShadowBTT};
    }
`

const LogOutButton = styled.div`
    cursor: pointer;
    color: ${branding.sideIconBar.sideIconColorDark};
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
`

const NextButton = styled.button`
    flex: 0 0 auto;
    margin-bottom: 20px;
    padding: 5px;
    min-height: 35px;
    height: 35px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: #fff;
    background: ${branding.crsTabs.defaultActionItemActiveStateColor};
    transition: 0.5s;
    cursor: pointer;
    width: 125px;
    outline: none;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.5;
                  transition-property: none;
                  pointer-events: none;
                  cursor: auto;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

const ButtonPlaceholder = styled.div`
    flex: 0 0 auto;
    margin-bottom: 20px;
    padding: 5px;
    min-height: 35px;
    height: 35px;
    border-radius: 5px;
    background: #fff;
`

const BackButton = styled.button`
    flex: 0 0 auto;
    margin-bottom: 20px;
    padding: 5px;
    min-height: 35px;
    height: 35px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: ${branding.crsTabs.defaultActionItemActiveStateColor};
    background: #fff;
    transition: 0.5s;
    cursor: pointer;
    width: 125px;
    outline: none;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.5;
                  transition-property: none;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

const LogOutDeleteButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;

    @media ${device.mobile} {
        padding: 10px 0 20px 0;
    }
`

const NavigationButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
`

interface BottomNavigationProps {
    activeTab: EditTab
    setActiveTab: (value: EditTab) => void
    numberOfEditedTabs: number
    setPercentage: (value: number) => void
    validationErrors: boolean
    showProfileEditedConfirmation: boolean
    onConfirmationModalClose: () => void
    saveProfile: () => void
}

const BottomNavigation: React.FunctionComponent<BottomNavigationProps> = (props: BottomNavigationProps) => {
    const strings = useLanguageState().getStrings()

    const { useMobileDesign } = useWindowDimensions()

    const userState = useLoggedInState()
    const history = useHistory()
    const appState = useAppState()
    const favoriteState = useFavoriteState()
    const chime = useChimeContext()

    const [showDeleteProfileWarning, setShowDeleteProfileWarning] = useState<boolean>(false)
    const [showDeleteProfileConfirmation, setShowDeleteProfileConfirmation] = useState<boolean>(false)

    const [showLogOutConfirmation, setShowLogoutConfirmation] = useState<boolean>(false)

    const tabsList = branding.userRegistrationSite.tabs
    let activeTabIndex: number = tabsList.indexOf(props.activeTab)

    async function deleteProfileAction(userId: string | undefined, userPool: string) {
        await deleteUserProfile(userId, userPool)
    }

    function updateTab() {
        if (props.validationErrors) {
            return
        }

        props.setPercentage(getPercentage(props.numberOfEditedTabs))

        props.setActiveTab(tabsList[activeTabIndex + 1])
    }

    return (
        <>
            <BottomNavigationRoot>
                <LogOutDeleteButtonsContainer>
                    <LogOutButton
                        onClick={() => {
                            setShowLogoutConfirmation(true)
                        }}
                    >
                        {strings.userRegistrationSite.quitAndLogOutButtonText}
                    </LogOutButton>

                    <LogOutButton onClick={() => setShowDeleteProfileWarning(true)}>
                        {strings.myProfilePageContent.deleteProfileLinkLabel}
                    </LogOutButton>
                </LogOutDeleteButtonsContainer>
                <NavigationButtonsContainer>
                    {activeTabIndex === 0 ? (
                        <>
                            {useMobileDesign && <ButtonPlaceholder />}
                            <NextButton
                                disabled={props.validationErrors}
                                onClick={() => {
                                    updateTab()
                                }}
                            >
                                {strings.userRegistrationSite.nextButtonText}
                            </NextButton>
                        </>
                    ) : activeTabIndex === tabsList.length - 1 ? (
                        <>
                            <BackButton onClick={() => props.setActiveTab(tabsList[activeTabIndex - 1])}>
                                {strings.userRegistrationSite.backButtonText}
                            </BackButton>
                            <NextButton
                                disabled={props.validationErrors}
                                onClick={() => {
                                    if (props.validationErrors) {
                                        return
                                    }

                                    props.setPercentage(getPercentage(props.numberOfEditedTabs))

                                    props.saveProfile()
                                }}
                            >
                                {strings.userRegistrationSite.finishButtonText}
                            </NextButton>
                        </>
                    ) : (
                        <>
                            <BackButton onClick={() => props.setActiveTab(tabsList[activeTabIndex - 1])}>
                                {strings.userRegistrationSite.backButtonText}
                            </BackButton>
                            <NextButton
                                disabled={props.validationErrors}
                                onClick={() => {
                                    updateTab()
                                }}
                            >
                                {strings.userRegistrationSite.nextButtonText}
                            </NextButton>
                        </>
                    )}
                </NavigationButtonsContainer>
            </BottomNavigationRoot>

            {showLogOutConfirmation && (
                <ConfirmationModal
                    dialogWidth="390px"
                    title={strings.userRegistrationSite.logoutConfirmationModalTitle}
                    text={strings.userRegistrationSite.logoutConfirmationModalText}
                    acceptButtonText={strings.systemCheck.yes}
                    dismissButtonText={strings.globalTexts.cancel}
                    accept={() => {
                        setShowLogoutConfirmation(false)

                        logoutUser(userState, history, appState, favoriteState, chime)
                    }}
                    dismiss={() => setShowLogoutConfirmation(false)}
                />
            )}

            {showDeleteProfileWarning && (
                <ConfirmationModal
                    dialogWidth="390px"
                    title={strings.myProfilePageContent.deleteProfileModalTitle}
                    text={strings.myProfilePageContent.deleteProfileModalText}
                    acceptButtonText={strings.myProfilePageContent.deleteProfileModalContinueButton}
                    dismissButtonText={strings.myProfilePageContent.deleteProfileModalCancelButton}
                    accept={() => {
                        setShowDeleteProfileWarning(false)
                        setShowDeleteProfileConfirmation(true)
                        deleteProfileAction(userState.user()?.profileId, branding.configuration.userPoolName)
                    }}
                    dismiss={() => setShowDeleteProfileWarning(false)}
                />
            )}

            {showDeleteProfileConfirmation && (
                <ConfirmationModal
                    dialogWidth="390px"
                    title={strings.myProfilePageContent.deleteProfileConfirmModalTitle}
                    text={strings.myProfilePageContent.deleteProfileConfirmModalText}
                    acceptButtonText={strings.myProfilePageContent.deleteProfileConfirmModalSubmit}
                    accept={() => {
                        setShowDeleteProfileConfirmation(false)
                    }}
                    dismiss={() => setShowDeleteProfileConfirmation(false)}
                    dismissButtonText={""}
                    hideDisagreeButton={true}
                />
            )}

            {props.showProfileEditedConfirmation && (
                <UserRegistrationConfirmationModal onClose={() => props.onConfirmationModalClose()} />
            )}
        </>
    )
}
