import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Product } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { globalSearchResultPageRoute } from "../../../navigationArea/RoutePaths"
import { useSuggestContext } from "../../../navigationArea/useSuggestContext"
import BadgeArea from "../../../ui/BadgeArea"
import BookmarkWithToggle from "../../../ui/BookmarkWithToggle"
import { InfoContentOrderType } from "../DetailPageBranding"
import { getSimpleCategories } from "./DetailPageSections"
import FocusImageMedia from "./FocusImageMedia"
import { filterMedia } from "./Media"

const ProductDetailInfoRoot = styled.div`
    overflow: auto;
`

const ProductMedia = styled.div`
    display: flex;
    float: left;
    padding: 0 40px 40px 0;
    width: 50%;
`

const ImageInFocus = styled.div<{ clickable: boolean }>`
    position: relative;
    width: 100%;
    height: 500px;
    // padding-bottom: 66%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: ${(props) => (props.clickable ? "pointer" : "default")};
    border-radius: 5px;
    border: 2px solid ${branding.listDividerColor};

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        user-select: none;
    }
`

const ProductBasicInfo = styled.div``

const ReadMoreBtn = styled.div`
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: 10px;
`

const ProductAttributesContainer = styled.div`
    font-size: ${branding.organizationDetailPageContent.companyTeaserFontSize};
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 10px;
`

const ProductTypeMarker = styled.div`
    cursor: pointer;
    text-decoration: underline;

    &.productDetail {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    &.list {
        font-size: 14px;
        font-weight: normal;
        color: ${branding.exhibitorsPageContent.productTypeTextColor};
        margin-bottom: 10px;
    }

    &.companyDetail {
        font-size: 14px;
        font-weight: normal;
        color: ${branding.exhibitorsPageContent.productTypeTextColor};
        margin-bottom: 10px;
    }
`

const ProductAttributeContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
`

const ProductAttributeName = styled.div`
    font-weight: bold;
    margin-right: 5px;
`

const ProductAttributeValue = styled.div``

const ProductTeaser = styled.div`
    font-size: ${branding.organizationDetailPageContent.companyTeaserFontSize};
    font-weight: 400;
    white-space: pre-line;
    margin-top: 10px;
    margin-bottom: 10px;
`

interface ProductTypeProps {
    product?: Product
    view: "productDetail" | "companyDetail" | "list"
}

export const ProductType = (props: ProductTypeProps) => {
    const suggestContext = useSuggestContext()
    const history = useHistory()

    const onProductTypeSelect = (typeId?: string, typeName?: string) => {
        if (typeId && typeName) {
            suggestContext.addExternalSuggestion({
                icon: "",
                id: "",
                value: "prodtype_" + typeId,
                type: "product",
                title: typeName,
                subtitle: "",
                active: true
            })
            history.push(globalSearchResultPageRoute)
        }
    }

    return (
        <>
            {props.product?.typeId && props.product?.typeName && (
                <ProductTypeMarker
                    onClick={(e: any) => {
                        e.preventDefault()
                        onProductTypeSelect(props.product?.typeId, props.product?.typeName)
                    }}
                    className={props.view as string}
                >
                    {props.product?.typeName || ""}
                </ProductTypeMarker>
            )}
        </>
    )
}

interface ProductDetailInfoProps {
    product?: Product
}

const ProductAttributes = (props: ProductDetailInfoProps) => {
    return (
        <ProductAttributesContainer>
            {branding.exhibitorsPageContent.showProductTypeInfo && <ProductType product={props.product} view="productDetail" />}
            {props.product?.productProperties?.map((item, i) => (
                <div key={i}>
                    {item.name && item.value && (
                        <ProductAttributeContainer>
                            <ProductAttributeName>{item.name}:</ProductAttributeName>
                            <ProductAttributeValue>
                                {item.value} {item.unit || ""}
                            </ProductAttributeValue>
                        </ProductAttributeContainer>
                    )}
                </div>
            ))}
        </ProductAttributesContainer>
    )
}

const MAX_DESCRIPTION_TEXT_LENGTH = 900
const ProductDetailInfo = (props: ProductDetailInfoProps) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const [productDescription, setProductDescription] = useState("")
    const [productImageInFocus, setProductImageInFocus] = useState("")
    const [showMoreBtnActive, setShowMoreBtnActive] = useState(false)
    const [showMoreBtnClicked, setShowMoreBtnClicked] = useState(false)

    useEffect(() => {
        if (props.product?.description?.text)
            props.product.description.text.length > MAX_DESCRIPTION_TEXT_LENGTH
                ? setShowMoreBtnActive(true)
                : setShowMoreBtnActive(false)
        else setShowMoreBtnActive(false)
    }, [props.product])

    useEffect(() => {
        setShowMoreBtnClicked(false)
    }, [lang])

    useEffect(() => {
        if (showMoreBtnClicked) {
            setProductDescription(props.product?.description.text || "")
        } else {
            let description = props.product?.description?.text?.slice(0, MAX_DESCRIPTION_TEXT_LENGTH)
            if (props.product?.description?.text && props.product.description.text?.length > MAX_DESCRIPTION_TEXT_LENGTH) {
                description += "..."
            }
            setProductDescription(description || "")
        }
        setProductImageInFocus(props.product?.pictureURL || "")
        // eslint-disable-next-line
    }, [showMoreBtnClicked, props.product])

    return (
        <ProductDetailInfoRoot>
            {props.product && filterMedia(props.product.attachments, true).length > 0 && (
                <ProductMedia>
                    <FocusImageMedia
                        attachments={filterMedia(props.product.attachments, true)}
                        imageInFocus={productImageInFocus}
                        organizationId={props.product.organizations.length ? props.product.organizations[0].id : undefined}
                        organization={props.product.organizations.length ? props.product.organizations[0] : undefined}
                    />
                </ProductMedia>
            )}

            {props.product && filterMedia(props.product.attachments, true).length === 0 && props.product?.pictureURL && (
                <ProductMedia>
                    <ImageInFocus clickable={false}>
                        <img src={props.product?.pictureURL} alt=""></img>
                    </ImageInFocus>
                </ProductMedia>
            )}

            <ProductBasicInfo>
                <Col>
                    <Row>
                        <Col className="p-0" md={10}>
                            <h3 style={{ fontSize: "22px", fontWeight: 800, marginBottom: "15px" }}>{props.product?.name}</h3>
                        </Col>
                        <Col className="p-0 text-right" md={2}>
                            <BookmarkWithToggle
                                newBookmarkItem={true}
                                fontSize={"25px"}
                                color={branding.sideIconBar.sideIconColorDark}
                                favIconBasic={true}
                                type="product"
                                id={props.product?.id as string}
                                name={props.product?.name as string}
                            />
                        </Col>
                    </Row>
                </Col>
                {branding.productDetailPageContent.infoContentOrder.map((item, index) => {
                    switch (item) {
                        case InfoContentOrderType.ATTRIBUTES: {
                            return <ProductAttributes product={props.product} />
                        }
                        case InfoContentOrderType.DESCRIPTION: {
                            return (
                                <>
                                    <ProductTeaser>
                                        {productDescription?.split("\\n")?.map((item, i) => (
                                            <span key={i}>
                                                {item}
                                                <br />
                                            </span>
                                        ))}
                                    </ProductTeaser>
                                    {showMoreBtnActive && (
                                        <ReadMoreBtn onClick={() => setShowMoreBtnClicked(!showMoreBtnClicked)}>
                                            {showMoreBtnClicked
                                                ? strings.productDetailPageContent.showLessText
                                                : strings.productDetailPageContent.showMoreText}
                                        </ReadMoreBtn>
                                    )}
                                </>
                            )
                        }

                        case InfoContentOrderType.BADGES: {
                            return (
                                <BadgeArea
                                    categories={getSimpleCategories(
                                        props.product?.categories || [],
                                        branding.productDetailPageContent.showCategoryHierarchy
                                    )}
                                    marginTop="20px"
                                />
                            )
                        }

                        default:
                            return null
                    }
                })}
            </ProductBasicInfo>
        </ProductDetailInfoRoot>
    )
}

export default ProductDetailInfo
