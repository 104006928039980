import queryString from "query-string"
import { useEffect, useState } from "react"
import * as React from "react"
import { NavLink, useHistory } from "react-router-dom"
import styled from "styled-components"
import { isUserEdited } from "../../App"
import { trackVisit, UserOrganizationVisitType } from "../../backendServices/TrackingServices"
import { ConferenceRoom, Person, UserConferenceRoomAccessType } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { MeetingRoomGroupType, MeetingRoomType } from "../../conference/AudioVideoBranding"
import { getExternalMeetingId, getUrlForMeeting } from "../../conference/context/ChimeContext"
import { getIamPartOf } from "../../globalStates/IAmPartOf"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import { useOnlineUsers } from "../../tracking/LoungeUsers"
import CrsPersons from "../../ui/CrsPersons"
import { useGuestModal } from "../../ui/modals/GuestModal"
import { IconVCLarge } from "../../ui/Icons"
import { DotLive, LiveTitle, LobbySessionTimeDivLive } from "../../ui/MeetingDetails"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { hasAccessToOrganization } from "../../utils/Authorization"
import { isExplorationPhase, isPostEventPhase } from "../../utils/EventPhaseChecker"
import { DetailNavLink } from "../detailPages/DetailNavLink"
import { MobileShowfloorTile } from "../reception/LobbyPageContent"
import ActionButton, { ActionButtonTheme } from "../videoPlayer/ActionButton"
import { MeetingKind } from "../../backendServices/MeetingServices"

const queryParams: any = queryString.parse(window.location.search)

const Tile = styled(NavLink)<{ $pictureUrl?: string; margin?: string; disableOverlayGradient?: boolean }>`
    position: relative;
    display: flex;
    flex-flow: column;
    width: 500px;
    height: 280px;
    background: ${(props) =>
        [
            props.disableOverlayGradient ? null : branding.meetingRoomGroupsOverlayValue,
            props.$pictureUrl ? `url("${props.$pictureUrl}");` : null
        ]
            .filter(Boolean)
            .join(",")};
    color: ${(props) => (props.$pictureUrl ? "#fff" : "#000")};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
    margin: ${(props) => props.margin ?? "20px 20px 0 20px"};
    color: white !important;
    cursor: ${(isExplorationPhase && !(getIamPartOf(queryParams) === "onboarding")) || isPostEventPhase ? "default" : "pointer"};
    border-radius: 5px;

    &.private {
        cursor: not-allowed;
    }

    &:hover,
    &:active,
    &:link,
    &:focus {
        text-decoration: none;
        color: #fff;
    }

    .pers-row {
        div {
            width: 30px;
            height: 30px;
            margin-right: 5px;
            font-size: 14px;
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
    & h2 {
        font-family: ${branding.font2};
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
    }

    &:after {
        border-radius: 5px;
    }

    &:before {
        border-radius: 5px;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${(props) => [props.disableOverlayGradient ? null : branding.meetingRoomGroupsOverlayValue]};
        opacity: 0.2;
        z-index: 1;
    }
    & > * {
        z-index: 2;
    }
    .btn-reqest-access {
        font-family: ${branding.font1};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        border-radius: 1rem;
        &.focus,
        &:focus,
        &.hover,
        &:hover,
        &.active,
        &:active {
            box-shadow: none !important;
        }
    }

    .bottom-overlay {
        position: absolute;
        height: 30%;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }
`

const MeetingRoomGroupTitle = styled.h2`
    white-space: pre-line;
    font-family: ${branding.font1};
    font-style: ${branding.meetingRoomGroupsTitleFontStyle};
    font-size: ${branding.meetingRoomGroupsTitleFontSize};
    /* font: ${branding.meetingRoomGroupsTitleFontStyle
        ? branding.meetingRoomGroupsTitleFontStyle
        : "normal bold 18px/23px"} !important; */
`

const HostedBy = styled.div`
    font-family: ${branding.font1};
    font-style: ${branding.meetingRoomGroupsHosterFontStyle};
    font-size: ${branding.meetingRoomGroupsHosterFontSize};
    /* font: ${branding.meetingRoomGroupsHosterFontStyle ? branding.meetingRoomGroupsHosterFontStyle : "normal 300 10px/12px"}; */
    & div {
        display: inline;
        text-decoration: underline;
    }
`

const MeetingRoomGroupDescription = styled.div`
    margin-top: auto;
    white-space: pre-line;
    max-width: 260px;
    font-family: ${branding.font1};
    font-style: ${branding.meetingRoomGroupsDescriptionFontStyle};
    font-size: ${branding.meetingRoomGroupsDescriptionFontSize};

    /* font: ${branding.meetingRoomGroupsDescriptionFontStyle
        ? branding.meetingRoomGroupsDescriptionFontStyle
        : "normal 300 12px/17px"}; */
`

/* #region  Meeting Room Group Component */

const PersonsRowWrapper = styled.div`
    .persons-row > div > div:last-child > div {
        position: absolute;
    }

    /* hack for CrsPersons */
    @media (max-width: 1400px) {
        .persons-row img {
            width: 36px;
            height: 36px;
        }
        .persons-row > div > div:last-child > div {
            position: absolute;
            width: 30px;
            height: 30px;
        }
    }
    @media (max-width: 1000px) {
        .persons-row img {
            width: 30px;
            height: 30px;
        }
        .persons-row > div > div:last-child > div {
            position: absolute;
            width: 30px;
            height: 30px;
        }
    }
`

const MobileVCInfosItem = styled.div`
    display: flex;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    width: 171px;
    margin-top: 10px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

const MobileVCInfos = styled.div`
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 5px;
    margin-left: 10px;

    ${MobileVCInfosItem}:first-child {
        width: 171px;
        font-weight: bold !important;
        font-size: 14px !important;
        line-height: 16px !important;
        -webkit-line-clamp: 3;
    }
`

export interface MeetingRoomGroupProps {
    meetingRoomGroupConfig: MeetingRoomGroupType | ConferenceRoom
    // accessStatus?: string
    source?: "LOBBY" | "ORG_DETAIL" | "VC"
    setRefresh?: () => void
    margin?: string
    roomType: MeetingKind
    isMobile?: boolean
    companyStaff?: Person[]
    // pass userAccessState.isUnlocked() here to update component when access is changed in backoffice
    // we don't actually do anything with this property here
}

const MeetingRoomGroup: React.FunctionComponent<MeetingRoomGroupProps> = (props) => {
    const onlineUsers = useOnlineUsers()
    const persons = onlineUsers.getUsersInRoomOrLounge(props.meetingRoomGroupConfig.id).map((x: any) => {
        return { logoUrl: x.user.pictureUrl, id: x.id, name: x.user.name, userId: x.userId }
    })
    const history = useHistory()
    const userState = useLoggedInState()
    const loggedInUserId = useLoggedInState().user()?.profileId
    const loggedUser = useLoggedInState().user()
    const visitType: UserOrganizationVisitType =
        props.source === "ORG_DETAIL" ? "VC#DETAIL" : props.source === "LOBBY" ? "VC#LOBBY" : "VC#VC"
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const { showGuestModal, GuestModal } = useGuestModal()
    const queryParams: any = queryString.parse(window.location.search)
    const userAccessState = useUserRestrictedAreaAccess()
    const isMeetingRoomUnlocked = userAccessState.isUnlocked(props.meetingRoomGroupConfig)

    const [hasModerators, setHasModerators] = useState<boolean>(false)

    const onEnterCafe = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()

        if ((isExplorationPhase && !(getIamPartOf(queryParams) === "onboarding")) || isPostEventPhase || !isUserEdited(userState))
            return

        if (
            loggedUser &&
            ((props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId ??
                (props.meetingRoomGroupConfig as ConferenceRoom).organization) &&
            !hasAccessToOrganization(
                loggedUser,
                (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId ??
                    (props.meetingRoomGroupConfig as ConferenceRoom).organization
            )
        ) {
            showGuestModal()
            return
        }

        if (isMeetingRoomUnlocked) {
            if (props.roomType === "conferenceroom") {
                history.push(
                    "/meeting/" + getExternalMeetingId(props.meetingRoomGroupConfig.id, "conferenceroom") + "/createorjoin"
                )
            } else {
                history.push("/meetings/" + props.meetingRoomGroupConfig.id)
            }
            if (
                loggedInUserId &&
                (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId &&
                props.roomType === "virtualCafe"
            ) {
                trackVisit(
                    loggedInUserId,
                    (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId,
                    visitType,
                    props.meetingRoomGroupConfig.id
                )
            }
        }
        if (props.setRefresh) props.setRefresh()
    }

    useEffect(() => {
        onlineUsers.subscribe()
        return () => onlineUsers.unsubscribe()
    }, [props.meetingRoomGroupConfig.id]) //eslint-disable-line

    function checkIfConferenceRoomsHasModerators(): boolean {
        if (props.companyStaff) {
            for (let staff of props.companyStaff) {
                for (let conferenceRoomUser of persons) {
                    if (
                        (staff.id && staff.id === conferenceRoomUser.userId) ||
                        (staff.userId && staff.userId === conferenceRoomUser.userId)
                    ) {
                        return true
                    }
                }
            }
        }

        return false
    }

    useEffect(() => {
        setHasModerators(checkIfConferenceRoomsHasModerators())
        // eslint-disable-next-line
    }, [persons])

    if (props.isMobile) {
        return (
            <MobileShowfloorTile
                onClick={onEnterCafe}
                to={`${
                    props.roomType === "virtualCafe"
                        ? "/meetings/" + props.meetingRoomGroupConfig.id
                        : "/meeting/" + getExternalMeetingId(props.meetingRoomGroupConfig.id, "conferenceroom") + "/createorjoin"
                }`}
                $background={
                    (props.meetingRoomGroupConfig as MeetingRoomGroupType).pictureUrl ??
                    (props.meetingRoomGroupConfig as ConferenceRoom).backgroundImage
                }
            >
                <MobileVCInfos>
                    <MobileVCInfosItem>
                        {(props.meetingRoomGroupConfig as MeetingRoomGroupType).title ??
                            (props.meetingRoomGroupConfig as ConferenceRoom).title}
                    </MobileVCInfosItem>
                    <MobileVCInfosItem>
                        {(props.meetingRoomGroupConfig as MeetingRoomGroupType).description ??
                            (props.meetingRoomGroupConfig as ConferenceRoom).teaser}
                    </MobileVCInfosItem>
                </MobileVCInfos>
            </MobileShowfloorTile>
        )
    }

    return (
        <>
            <Tile
                to={`${
                    props.roomType === "virtualCafe"
                        ? "/meetings/" + props.meetingRoomGroupConfig.id
                        : "/meeting/" + getExternalMeetingId(props.meetingRoomGroupConfig.id, "conferenceroom") + "/createorjoin"
                }`}
                margin={props.margin}
                onClick={onEnterCafe}
                $pictureUrl={
                    (props.meetingRoomGroupConfig as MeetingRoomGroupType).pictureUrl ??
                    (props.meetingRoomGroupConfig as ConferenceRoom).backgroundImage
                }
                disableOverlayGradient={branding.meetingRoomGroupsDisablePictureOverlay}
                className={!isMeetingRoomUnlocked ? "private" : undefined}
                style={{ marginLeft: "0px", marginRight: branding.meetingRoomMarginRight }}
            >
                {(props.meetingRoomGroupConfig as MeetingRoomGroupType).bottomExtraOverlay && (
                    <div className={"bottom-overlay"}></div>
                )}
                <div className="row">
                    <div
                        className={
                            (props.meetingRoomGroupConfig as MeetingRoomGroupType).isPrivate ??
                            (props.meetingRoomGroupConfig as ConferenceRoom).accessType ===
                                UserConferenceRoomAccessType.ORGA_MANAGED
                                ? "col-7"
                                : "col-12"
                        }
                    >
                        <MeetingRoomGroupTitle>
                            {(props.meetingRoomGroupConfig as MeetingRoomGroupType).titleVisible
                                ? props.meetingRoomGroupConfig.title
                                : (props.meetingRoomGroupConfig as ConferenceRoom).title}
                        </MeetingRoomGroupTitle>
                        {(props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationName &&
                            (props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId &&
                            props.source !== "ORG_DETAIL" && (
                                <HostedBy>
                                    {strings.globalTexts.hostedByText}{" "}
                                    <DetailNavLink
                                        id={(props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationId}
                                        name={(props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationName}
                                        type="organization"
                                        source={props.roomType === "virtualCafe" ? "VC" : undefined}
                                    >
                                        {(props.meetingRoomGroupConfig as MeetingRoomGroupType).organizationName}
                                    </DetailNavLink>
                                </HostedBy>
                            )}
                    </div>
                    <ActionButton restrictedArea={props.meetingRoomGroupConfig} theme={ActionButtonTheme.MEETING_ROOM} />
                </div>
                <PersonsRowWrapper style={{ marginTop: "auto" }} className="row align-items-center">
                    <div className={(persons.length > 3 ? "col-md-6" : "col-md-8") + " col-12"}>
                        {((props.meetingRoomGroupConfig as MeetingRoomGroupType).description ||
                            (props.meetingRoomGroupConfig as ConferenceRoom).teaser) && (
                            <MeetingRoomGroupDescription style={{ width: "unset" }}>
                                {(props.meetingRoomGroupConfig as MeetingRoomGroupType).description ||
                                    (props.meetingRoomGroupConfig as ConferenceRoom).teaser}
                            </MeetingRoomGroupDescription>
                        )}
                    </div>
                    <div
                        className={
                            (persons.length > 3 ? "col-md-6" : "col-md-4") + " col-12 mt-3 mt-md-0 align-self-end persons-row"
                        }
                    >
                        {props.roomType === "conferenceroom" && hasModerators && (
                            <div>
                                <LobbySessionTimeDivLive addTopMargin={true}>
                                    <DotLive />
                                    <LiveTitle>
                                        <h6 style={{ fontSize: "12px", fontFamily: branding.font1 }}>
                                            {strings.receptionPage.nowLive}
                                        </h6>
                                    </LiveTitle>
                                </LobbySessionTimeDivLive>
                            </div>
                        )}

                        {props.roomType !== "conferenceroom" && (
                            <CrsPersons
                                justifycontent="flex-end"
                                layoutType={1}
                                showModalPopup={false}
                                persons={persons as Person[]}
                                personsToShow={4}
                                width={"30px"}
                                height={"30px"}
                            ></CrsPersons>
                        )}
                    </div>
                </PersonsRowWrapper>
            </Tile>
            <GuestModal />
        </>
    )
}
const MeetingRoomIconContainer = styled.div`
    width: 100%;
    height: 280px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
`

export default MeetingRoomGroup
/* #endregion */

/* #region  Meeting Room Component */
interface MeetingRoomProps {
    meetingRoom: MeetingRoomType
    groupId: string
    organizationId: string
    setRefresh?: () => void
}
export const MeetingRoom: React.FunctionComponent<MeetingRoomProps> = (props) => {
    const room = props.groupId + "/" + props.meetingRoom.id
    const onlineUsers = useOnlineUsers()
    const persons = onlineUsers.getUsersInRoomOrLounge(room).map((x: any) => {
        return { logoUrl: x.user.pictureUrl, name: x.user.name }
    })
    const history = useHistory()
    const loggedInUser = useLoggedInState().user()
    const windowSize = useWindowDimensions()

    useEffect(() => {
        onlineUsers.subscribe()
        return () => onlineUsers.unsubscribe()
    }, [room]) //eslint-disable-line

    const onEnterRoom = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()
        history.push(getUrlForMeeting(props.meetingRoom.id, "virtualCafe"))
        if (loggedInUser && props.organizationId) {
            trackVisit(loggedInUser.profileId, props.organizationId, "VCROOM", props.groupId + "/" + props.meetingRoom.id)
        }
        if (props.setRefresh) props.setRefresh()
    }

    let customClassName = "d-flex flex-row justify-content-between align-items-center w-100"

    if (windowSize.width <= 1800) customClassName = "d-flex flex-column justify-content-center align-items-start w-100"

    return (
        <Tile
            to={getUrlForMeeting(props.meetingRoom.id, "virtualCafe")}
            className={"tile-inside-vg-room"}
            onClick={onEnterRoom}
            $pictureUrl={props.meetingRoom.pictureUrl}
            disableOverlayGradient={branding.meetingRoomGroupsDisablePictureOverlay}
        >
            <div className="d-flex justify-content-between flex-column align-items-start h-100">
                <MeetingRoomIconContainer>{IconVCLarge({ width: "120", height: "100" })}</MeetingRoomIconContainer>
                {/* <h2>{props.title}</h2> TODO h2 makes all CAPS */}
                <MeetingRoomGroupTitle>{props.meetingRoom.titleVisible && props.meetingRoom.title}</MeetingRoomGroupTitle>
                <div className={customClassName}>
                    <div className="mb-2" style={{ maxHeight: "140px", overflow: "hidden" }}>
                        {" "}
                        {/* Better option would be oveflow scroll, but we know that scoll bars do not behave the same on pc-s */}
                        {props.meetingRoom.description && (
                            <MeetingRoomGroupDescription>{props.meetingRoom.description}</MeetingRoomGroupDescription>
                        )}
                    </div>
                    <div className="crs-persons-parent">
                        <CrsPersons
                            layoutType={1}
                            showModalPopup={false}
                            persons={persons as Person[]}
                            personsToShow={4}
                        ></CrsPersons>
                    </div>
                </div>
            </div>
        </Tile>
    )
}

/* #endregion */
