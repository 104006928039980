import { useEffect, useRef, useState } from "react"
import * as React from "react"
import { Tooltip } from "react-bootstrap"
import styled from "styled-components"
import branding from "../branding/branding"
import { getTileViewMinReqWidth, PagesUsingTileView } from "../globalStates/TileViewConfig"
import { device, DesktopVersionContainer } from "../utils/Device"
import {
    IconArrowHeadDown,
    IconArrowHeadUp,
    IconBookmarkFilled,
    IconCardView,
    IconChevronLeft,
    IconChevronRight,
    IconListView
} from "./Icons"
import useWindowDimensions from "./WindowDimensionsHook"
import { CustomOverlayTrigger } from "./CustomOverlayTrigger"
import { useAppState } from "../globalStates/AppState"
import { isMobile } from "react-device-detect"

const CrsTabsRoot = styled.div<{ showOnlyActionButtons: boolean; showShadow?: boolean; removeBorderBottom?: boolean }>`
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0 auto;
    color: ${branding.crsTabs.tabItemDefaultTextColor};
    background-color: ${branding.crsTabs.defaultBackgroundColor};
    overflow: hidden;
    min-height: 30px;
    padding-bottom: ${(props) => (props.showOnlyActionButtons ? "44px" : "0")};
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};

    @media ${device.tablet} {
        padding: 0 25px;
    }
`

const TabsHeader = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 10px 10px 0;
    z-index: 2;
    overflow-x: auto;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
        display: none;
    }
`

const TabItem = styled.div<{ width: number; fontSize: string }>`
    display: inline-block;
    box-sizing: content-box;
    min-width: ${(props) => (props.width ? `${props.width}px` : "200px")};
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-family: ${branding.font1};
    font-size: ${(props) => props.fontSize || branding.crsTabs.tabItemDefaultFontSize};
    line-height: 12px;
    cursor: pointer;
    user-select: none;
    &.active {
        font-weight: bold;
        color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
    }

    @media (max-width: 768px) {
        min-width: ${(props) => (props.width ? `${props.width}px` : "120px")};
    }
`

const ActiveTabItemIndicator = styled.div<{ width: number; left: number }>`
    min-width: ${(props) => `${props.width}px`};
    height: 3px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: ${(props) => `${props.left}px`};
    background-color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
    transition: left 0.5s;
`

const TabHeaderActions = styled.div<{ showShadow: boolean }>`
    position: absolute;
    right: 23px;
    top: 0;
    bottom: 1px;
    z-index: 10;
    background-color: #fff;
    display: flex;
    align-items: center;

    // @media ${device.mobile} {
    //     right: 0px;
    //     width: 50px;
    //     box-shadow: ${(props) => (props.showShadow ? "0px 4px 8px 1px rgba(217, 217, 217, 1)" : "initial")};
    // }
`

const TabHeaderActionsItem = styled.div`
    cursor: pointer;
    :last-child {
        margin-left: 10px;
    }
`
const SliderControllLeftRoot = styled.div`
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    cursor: pointer;
`

const SliderControllRightRoot = styled.div`
    background-color: #fff;
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    cursor: pointer;
`

const SliderControllLeftAction = styled.div``

const SliderControllRightAction = styled.div``

const SliderShadowLeft = styled.div<{ showShadow: boolean }>`
    width: 80px;
    height: 100%;
    background: ${(props) =>
        props.showShadow ? "linear-gradient(90deg, #FFFFFF 9.59%, rgba(255, 255, 255, 0) 108.26%)" : "none"};
    position: absolute;
    left: 24px;
    top: -5px;
    z-index: 10;
    pointer-events: none;
`

const SliderShadowRight = styled.div<{ showShadow: boolean }>`
    width: 80px;
    height: 100%;
    background: ${(props) =>
        props.showShadow ? "linear-gradient(90deg, #ffffff 9.59%, rgba(255, 255, 255, 0) 108.26%)" : "none"};
    transform: rotate(180deg);
    position: absolute;
    right: 24px;
    top: -5px;
    z-index: 10;
    pointer-events: none;
`

const SliderControlWrapper = styled.div<{ width?: string; centerItems?: boolean }>`
    position: relative;
    margin: ${(props) => (props.centerItems ? "0 auto" : "0")};
    width: ${(props) => (props.width ? props.width : "90%")};
    @media (max-width: 768px) {
        width: 98%;
    }
`

const BorderContainer = styled.div<{ removeBorderBottom?: boolean }>`
    border-bottom: ${(props) => (props.removeBorderBottom ? "none" : "1px solid " + branding.crsTabs.defaultBorderColor)};
`

export enum ActionTypes {
    TILELIST = "TILELIST",
    BOOKMARK = "BOOKMARK",
    TOGGLEFILTERS = "TOGGLEFILTERS"
}

export interface TabItemType {
    label: string
    value: string
    actions?: ActionTypes[]
}

/**
 * Enum for viewmodes. LIST = 0, TILES = 1
 * @readonly
 * @enum {number}
 */
export enum ViewMode {
    LIST,
    TILES
}

interface CrsTabsProps {
    /**
     * List of tab items to be rendered inside CrsTabs component.
     * @type {TabItemType[]}
     */
    tabItems: TabItemType[]

    /**
     * Active/selected tab item.
     */
    activeItem?: any

    /**
     * Called every time when user click on some tab item. Makes clicked tab as active.
     * @param tabItem - represents the id of clicked tab item
     */
    onTabItemClicked?: (tabItem: string) => void

    /**
     * Selected view mode.
     * @type {ViewMode}
     */
    viewMode?: ViewMode

    /**
     * This method is called every time when user toggle view mode.
     * @param viewMode - represents current selected view mode
     */
    onViewModeChange?: (viewMode: ViewMode) => void

    bookmarkFilter?: boolean
    setBookmarkFilter?: (value: boolean) => void
    filtersVisible?: boolean
    setFiltersVisible?: (value: boolean) => void
    onViewModeToggle?: () => void
    showOnlyActionButtons?: boolean
    style?: React.CSSProperties | undefined
    itemWidth?: number
    mobileItemWidth?: number
    showShadow?: boolean
    pageUsingTileView?: PagesUsingTileView
    hideBorderBottom?: boolean
    tabItemFontSize?: string
    tabItemPadding?: number
    width?: string
    hideControls?: boolean
    centerItems?: boolean
}

const CrsTabs = (props: CrsTabsProps) => {
    const windowSize = useWindowDimensions()
    const [widestTabItemWidth, setWidestTabItemWidth] = useState(0)
    const [activeIndicatorWidth, setActiveIndicatorWidth] = useState(0)
    const [activeIndicatorLeft, setActiveIndicatorLeft] = useState(0)
    const [selectedTabItem, setSelectedTabItem] = useState(
        props.activeItem
            ? props.tabItems.find((item: TabItemType) => item.value === props.activeItem) || props.tabItems[0]
            : props.tabItems[0]
    )

    const onTabItemClicked = (tabItem: TabItemType) => {
        if (props.onTabItemClicked) {
            props?.onTabItemClicked(tabItem.value)
        }

        setSelectedTabItem(tabItem)
    }

    const setWidestTabItemWidthMethod = () => {
        let tabItems = document.querySelectorAll("div.tab-item")
        let maxWidth = 0
        tabItems.forEach((element) => {
            if (element.clientWidth > maxWidth) {
                maxWidth = element.clientWidth
                if (!props.tabItemPadding) {
                    setActiveIndicatorWidth(maxWidth)
                    setWidestTabItemWidth(maxWidth)
                } else {
                    setActiveIndicatorWidth(props.tabItemPadding)
                    setWidestTabItemWidth(props.tabItemPadding)
                }
            }
            setActiveIndicatorLeft(props.tabItems.findIndex((x) => x.value === props.activeItem) * maxWidth)
        })
    }

    const showTileViewIcon = () => {
        if (!props.pageUsingTileView) return true
        let tileViewMinReqWidth = getTileViewMinReqWidth(props.pageUsingTileView)
        return windowSize.width > tileViewMinReqWidth ? true : false
    }

    useEffect(() => {
        setWidestTabItemWidthMethod()
        if (props.activeItem) {
            setSelectedTabItem(props.tabItems.find((item: TabItemType) => item.value === props.activeItem) || props.tabItems[0])
        }
        // eslint-disable-next-line
    }, [props.tabItems])

    function slide(isLeft?: boolean) {
        let sliderStep = widestTabItemWidth

        if (tabsHeaderRef && tabsHeaderRef.current) {
            const scrollLeft = tabsHeaderRef.current.scrollLeft
            const newLeft = isLeft ? scrollLeft - sliderStep : scrollLeft + sliderStep
            tabsHeaderRef.current.scroll({ left: newLeft + 25, behavior: "smooth" })
        }
    }

    const [leftArrowVisible, setLeftArrowVisible] = useState<boolean>(false)
    const [rightArrowVisible, setRightArrowVisible] = useState<boolean>(false)
    const tabsHeaderRef = useRef<any>()
    const tabsRootRef = useRef<any>()

    useEffect(() => {
        /**
         * When the tab items are loaded, check if the scroll container is
         * wider than its parrent, then check if we need the right arrow
         */

        if (tabsHeaderRef.current.scrollWidth > tabsHeaderRef.current.clientWidth) {
            setRightArrowVisible(true)
        } else {
            setRightArrowVisible(false)
        }
    }, [props.tabItems, windowSize])

    /**
     * Calculates the visibility of the arrows when scrolling
     */
    function handleScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
        if (event.currentTarget.scrollLeft > 0) {
            setLeftArrowVisible(true)
        } else {
            setLeftArrowVisible(false)
        }

        if (event.currentTarget.scrollLeft + event.currentTarget.clientWidth >= event.currentTarget.scrollWidth) {
            setRightArrowVisible(false)
        } else {
            setRightArrowVisible(true)
        }
    }

    /**
     * Calculates the visiblity of the arrows when clicking
     */
    function handleClick() {
        if (tabsHeaderRef.current.scrollLeft > 0) {
            setLeftArrowVisible(true)
        } else {
            setLeftArrowVisible(false)
        }

        if (tabsHeaderRef.current.scrollLeft + tabsHeaderRef.current.clientWidth >= tabsHeaderRef.current.scrollWidth) {
            setRightArrowVisible(false)
        } else {
            setRightArrowVisible(true)
        }
    }

    useEffect(() => {
        /**
         * When clicked on item, calculate the vislibity of the arrows
         */
        handleClick()
    }, [selectedTabItem, windowSize])

    return (
        <CrsTabsRoot
            ref={tabsRootRef}
            removeBorderBottom={props.hideBorderBottom}
            showShadow={props.showShadow}
            showOnlyActionButtons={props.showOnlyActionButtons || false}
            style={props.style}
        >
            <BorderContainer removeBorderBottom={props.hideBorderBottom}>
                <SliderControlWrapper width={props.width} centerItems={props.centerItems}>
                    {!props.hideControls && (
                        <>
                            <SliderControllLeft isVisible={leftArrowVisible} slide={slide} />

                            <SliderShadowLeft showShadow={leftArrowVisible} />
                        </>
                    )}

                    <TabsHeader
                        ref={tabsHeaderRef}
                        onScroll={(e) => handleScroll(e)}
                        style={{
                            display: props.showOnlyActionButtons ? "none" : "block",
                            paddingRight: selectedTabItem.actions?.includes(ActionTypes.TOGGLEFILTERS) ? "20px" : "10px"
                        }}
                    >
                        {props.tabItems.map((tabItem, index) => {
                            return (
                                <TabItem
                                    fontSize={props.tabItemFontSize || branding.crsTabs.tabItemDefaultFontSize}
                                    key={index}
                                    className={[selectedTabItem.value === tabItem.value ? "active" : "", "tab-item"].join(" ")}
                                    width={widestTabItemWidth}
                                    onClick={() => {
                                        onTabItemClicked(tabItem)
                                    }}
                                >
                                    {tabItem.label}
                                </TabItem>
                            )
                        })}

                        <ActiveTabItemIndicator width={activeIndicatorWidth} left={activeIndicatorLeft} />
                    </TabsHeader>

                    {!props.hideControls && (
                        <>
                            <SliderControllRight isVisible={rightArrowVisible} slide={slide} />

                            <SliderShadowRight showShadow={rightArrowVisible} />
                        </>
                    )}
                </SliderControlWrapper>

                <TabHeaderActions
                    showShadow={
                        (selectedTabItem.actions?.includes(ActionTypes.TOGGLEFILTERS) ?? false) && props.tabItems.length >= 3
                    }
                    style={{ marginBottom: props.showOnlyActionButtons ? "10px" : "0px" }}
                >
                    {selectedTabItem.actions?.includes(ActionTypes.TILELIST) && showTileViewIcon() && (
                        <ListViewToggleButton
                            initialTab={props.tabItems[0]}
                            viewMode={props.viewMode}
                            onViewModeChange={props.onViewModeChange}
                            onViewModeToggle={props.onViewModeToggle}
                        />
                    )}
                    {selectedTabItem.actions?.includes(ActionTypes.BOOKMARK) && (
                        <BookmarkButton bookmarkFilter={props.bookmarkFilter} setBookmarkFilter={props.setBookmarkFilter} />
                    )}
                    {/* {selectedTabItem.actions?.includes(ActionTypes.TOGGLEFILTERS) && (
                        <ToggleFiltersButton
                            isMobile={isMobile}
                            filtersVisible={props.filtersVisible}
                            setFiltersVisible={props.setFiltersVisible}
                        />
                    )} */}
                </TabHeaderActions>
            </BorderContainer>
        </CrsTabsRoot>
    )
}

interface ListViewButtonProps {
    initialTab: TabItemType
    viewMode?: ViewMode
    onViewModeChange?: (viewMode: ViewMode) => void
    onViewModeToggle?: () => void
}

const ListViewToggleButton: React.FunctionComponent<ListViewButtonProps> = (props: ListViewButtonProps) => {
    const appState = useAppState()
    const onToggleViewModeClicked = () => {
        if (props.viewMode === ViewMode.LIST) {
            setUserDefaultViewMode(ViewMode.TILES)
            if (props.onViewModeChange) {
                props.onViewModeChange(ViewMode.TILES)
            } else if (props.onViewModeToggle) {
                props.onViewModeToggle()
            }
        } else {
            setUserDefaultViewMode(ViewMode.LIST)
            if (props.onViewModeChange) {
                props.onViewModeChange(ViewMode.LIST)
            } else if (props.onViewModeToggle) {
                props.onViewModeToggle()
            }
        }
    }

    /**
     * Store the selected user viewmode inside localstorage.
     * @param viewMode - represents selected viewMode LIST or TILES
     */
    function setUserDefaultViewMode(viewMode: ViewMode) {
        let viewTypeSettings = JSON.parse(localStorage.getItem("viewTypeSettings") as any) || []
        let copyViewTypeSettings = [...viewTypeSettings]

        // keep same view mode for both tabs on business page
        if (appState.currentItem === "business") {
            const emptySettings =
                viewTypeSettings.filter((e: any) => {
                    return e.page === appState.currentItem
                }).length === 0

            if (emptySettings) {
                copyViewTypeSettings.push({ page: appState.currentItem, tab: null, viewMode: viewMode })
            } else {
                copyViewTypeSettings.map((el) => {
                    if (el.page === appState.currentItem && el.tab === null) {
                        return (el.viewMode = viewMode)
                    }

                    return null
                })
            }
        } else {
            const activeTab = appState.lastVisitedTab ?? props.initialTab.value
            const emptyActiveTabConfig =
                viewTypeSettings.filter((e: any) => {
                    return e.page === appState.currentItem && e.tab === activeTab
                }).length === 0

            if (emptyActiveTabConfig) {
                copyViewTypeSettings.push({
                    page: appState.currentItem,
                    tab: activeTab,
                    viewMode: viewMode
                })
            } else {
                copyViewTypeSettings.map((el) => {
                    if (el.page === appState.currentItem && el.tab === activeTab) {
                        return (el.viewMode = viewMode)
                    }

                    return null
                })
            }
        }

        localStorage.setItem("viewTypeSettings", JSON.stringify(copyViewTypeSettings))
    }

    return (
        <>
            <CustomOverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="listtileview-tooltip" style={{ fontFamily: branding.font1 }}>
                        {props.viewMode === ViewMode.TILES ? branding.crsTabs.listViewTooltip : branding.crsTabs.tileViewTooltip}
                    </Tooltip>
                }
            >
                <TabHeaderActionsItem onClick={onToggleViewModeClicked}>
                    <>
                        {props.viewMode === ViewMode.TILES
                            ? IconListView({ fill: branding.crsTabs.defaultActionItemColor })
                            : IconCardView({
                                  fill: branding.crsTabs.defaultActionItemActiveStateColor
                              })}
                    </>
                </TabHeaderActionsItem>
            </CustomOverlayTrigger>
        </>
    )
}

interface SliderControlerProps {
    isVisible?: boolean
    slide: (isLeft?: boolean) => void
}

const SliderControllLeft: React.FunctionComponent<SliderControlerProps> = (props: SliderControlerProps) => {
    const { useMobileDesign } = useWindowDimensions()

    return (
        <>
            {props.isVisible ? (
                <SliderControllLeftRoot>
                    {useMobileDesign ? (
                        <SliderControllLeftAction onTouchStartCapture={() => props.slide(true)}>
                            {IconChevronLeft({
                                fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                width: "25px",
                                height: "25px"
                            })}
                        </SliderControllLeftAction>
                    ) : (
                        <SliderControllLeftAction onClick={() => props.slide(true)}>
                            {IconChevronLeft({
                                fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                width: "25px",
                                height: "25px"
                            })}
                        </SliderControllLeftAction>
                    )}
                </SliderControllLeftRoot>
            ) : null}
        </>
    )
}

const SliderControllRight: React.FunctionComponent<SliderControlerProps> = (props: SliderControlerProps) => {
    const { useMobileDesign } = useWindowDimensions()

    return (
        <>
            {props.isVisible ? (
                <SliderControllRightRoot>
                    {useMobileDesign ? (
                        <SliderControllRightAction onTouchStartCapture={() => props.slide(false)}>
                            {IconChevronRight({
                                fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                width: "25px",
                                height: "25px"
                            })}
                        </SliderControllRightAction>
                    ) : (
                        <SliderControllRightAction onClick={() => props.slide(false)}>
                            {IconChevronRight({
                                fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                width: "25px",
                                height: "25px"
                            })}
                        </SliderControllRightAction>
                    )}
                </SliderControllRightRoot>
            ) : null}
        </>
    )
}

interface BookmarkButtonProps {
    bookmarkFilter?: boolean
    setBookmarkFilter?: (value: boolean) => void
}

const BookmarkButton: React.FunctionComponent<BookmarkButtonProps> = (props: BookmarkButtonProps) => {
    function handleClick() {
        if (props.setBookmarkFilter && props.bookmarkFilter !== undefined) {
            props.setBookmarkFilter(!props.bookmarkFilter)
        }
    }
    return (
        <DesktopVersionContainer>
            <CustomOverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="bookmarkview-tooltip" style={{ fontFamily: branding.font1 }}>
                        {branding.crsTabs.bookmarkViewTooltip}
                    </Tooltip>
                }
            >
                <TabHeaderActionsItem onClick={handleClick}>
                    {props.bookmarkFilter
                        ? branding.defaultToggleIcon
                            ? IconBookmarkFilled({
                                  fill: branding.crsTabs.defaultActionItemActiveStateColor
                              })
                            : IconBookmarkFilled({
                                  fill: branding.crsTabs.defaultActionItemColor
                              })
                        : IconBookmarkFilled({
                              fill: branding.crsTabs.defaultActionItemColor
                          })}
                </TabHeaderActionsItem>
            </CustomOverlayTrigger>
        </DesktopVersionContainer>
    )
}

interface ToggleFiltersButtonProps {
    isMobile: boolean
    filtersVisible?: boolean
    setFiltersVisible?: (value: boolean) => void
}

export const ToggleFiltersButton: React.FunctionComponent<ToggleFiltersButtonProps> = (props: ToggleFiltersButtonProps) => {
    function handleClick() {
        if (props.setFiltersVisible && props.filtersVisible !== undefined) {
            props.setFiltersVisible(!props.filtersVisible)
        }
    }

    // if (!props.isMobile) return null

    return isMobile ? (
        <TabHeaderActionsItem onTouchStartCapture={() => handleClick()}>
            {props.filtersVisible
                ? IconArrowHeadUp({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })
                : IconArrowHeadDown({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })}
        </TabHeaderActionsItem>
    ) : (
        <TabHeaderActionsItem onClick={() => handleClick()}>
            {props.filtersVisible
                ? IconArrowHeadUp({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })
                : IconArrowHeadDown({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })}
        </TabHeaderActionsItem>
    )
}

/**
 *
 * @param currentPage
 * @param currentTab
 * @returns user default viewmode LIST or TILES for visited page and tab.
 * Default view mode is stored inside localstorage.
 */
export function getUserDefaultViewMode(currentPage: string, currentTab: string | null): ViewMode | null {
    let userDefaultViewMode = JSON.parse(localStorage.getItem("viewTypeSettings") as any) || []
    let viewModeData
    // keep same view mode for both tabs on business page
    if (currentPage === "business") {
        viewModeData =
            userDefaultViewMode &&
            userDefaultViewMode.filter((e: any) => {
                return e.page === currentPage
            })[0]?.viewMode
    } else {
        viewModeData =
            userDefaultViewMode &&
            userDefaultViewMode.filter((e: any) => {
                return e.page === currentPage && e.tab === currentTab
            })[0]?.viewMode
    }
    // viewModeData
    // LIST = 0
    // TILES = 1

    return viewModeData
}

export default CrsTabs
